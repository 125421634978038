<template>
  <div class="bg-white p-3 border border-gray-200 shadow-xl rounded-md flex flex-col gap-y-3 bg-opacity-90">
    <div class="flex items-center justify-between gap-x-2">
      <button-element size="xsmall" type="light" @click="showTotal">
        Total
      </button-element>
      <button-with-menu-element
        :actions="getActionMenuActions"
        :truncate="false"
        button-text="Autres actions"
        close-after-action-executed
        size="xsmall"
        small-menu
        type="light"
        use-divider
      />
      <button-element
        size="xsmall"
        type="light"
        @click="createNewRoute"
      >
        Créer route
      </button-element>

      <route-manager-automatic-route-planning-buttons class="mx-2.5"/>

      <route-manager-action-box-date-selection/>
    </div>

    <div
      v-if="getTimeSlots.length > 0"
      class="flex flex-wrap items-center gap-x-2 gap-y-1 select-none w-96"
    >
      <route-manager-action-box-timeslot-checkbox
        v-for="timeslot in getTimeSlots"
        :key="timeslot.id"
        :end-time="timeslot.endTime"
        :start-time="timeslot.startTime"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import RouteManagerActionBoxDateSelection
  from "@/components/pages/RouteManager/Includes/ActionBox/Includes/RouteManagerActionBoxDateSelection";
import {mapActions, mapGetters, mapMutations} from "vuex";
import RouteManagerActionBoxTimeslotCheckbox
  from "@/components/pages/RouteManager/Includes/ActionBox/Includes/RouteManagerActionBoxTimeslotCheckbox";
import ButtonWithMenuElement from "@/components/elements/buttons/ButtonWithMenuElement";
import RouteManagerAutomaticRoutePlanningButtons
  from "@/components/pages/RouteManager/Includes/ActionBox/Includes/AutomaticRoutePlanning/RouteManagerAutomaticRoutePlanningButtons.vue";

export default {
  name: "RouteManagerActionBox",
  components: {
    RouteManagerAutomaticRoutePlanningButtons,
    ButtonWithMenuElement,
    RouteManagerActionBoxTimeslotCheckbox,
    RouteManagerActionBoxDateSelection,
    ButtonElement
  },
  methods: {
    ...mapActions('routeManager', [
      'createRouteForSelectedDeliveries',
      'fetchAllRoutes',
      'fetchDeliveries',
    ]),
    ...mapMutations('routeManager', [
      'showAllDeliveries',
      'showDeliveriesWithoutRouteOnly',
      'showFreshlyDeliveriesOnly',
      'clearSelectedDeliveries',
      'selectAllVisibleDeliveries'
    ]),
    createNewRoute() {
      this.createRouteForSelectedDeliveries().then(() => {
        this.$sendSuccessToast('Route created!');
      });
    },

    showTotal() {
      this.$sendInfoToast(`Durée total: ${this.getTotalDuration}min | Distance totale: ${this.getTotalDistance}km`);
    },
    refresh() {
      this.fetchAllRoutes();
      this.fetchDeliveries();
    }
  },
  computed: {
    ...mapGetters('routeManager', [
      'getAllPossibleTimeslots',
      'getTotalDistance',
      'getTotalDuration',
    ]),


    getActionMenuActions() {
      return [
        {
          label: 'Afficher tous les livraisons',
          action: () => {
            this.showAllDeliveries();
          },
        },
        {
          label: 'Afficher uniquement les livraisons sans route',
          action: () => {
            this.showDeliveriesWithoutRouteOnly();
          },
        },
        {
            label: 'Afficher uniquement les livraisons Freshly',
            action: () => {
                this.showFreshlyDeliveriesOnly();
            },
        },
        {
          label: 'Sélectionner tous les livraisons visibles',
          action: () => {
            this.selectAllVisibleDeliveries();
          },
        },
        {
          label: 'Déselectionner tous les livraisons',
          action: () => {
            this.clearSelectedDeliveries();
          },
        },
      ];
    },

    getTimeSlots() {
      let timeSlots = this.getAllPossibleTimeslots.flatMap(t => {
        return {
          startTime: this.$date(t.startDate).format('HH:mm'),
          endTime: this.$date(t.endDate).format('HH:mm'),
        };
      });

      // Remove duplicates
      return timeSlots.filter((value, index, self) => {
          return index === self.findIndex((t) => (
            t.startTime === value.startTime &&
            t.endTime === value.endTime
          ))
        }
      )
    }
  },
}
</script>

