import auth from "@/router/middlewares/authMiddleware";
import ShowUpcomingDeliveries from "@/components/pages/Subscription/Deliveries/ShowUpcomingDeliveries.vue";
import ShowSubscriptions from "@/components/pages/Subscription/ShowSubscriptions.vue";
import ShowSkipDates from "@/components/pages/Subscription/SkipDates/ShowSkipDates.vue";

export default [
    {
        component: ShowSubscriptions,
        meta: {
            middleware: [auth],
            permissions: ['READ_SUBSCRIPTION'],
            title: 'Abonnaments',
        },
        path: '/subscription',
        name: 'subscriptions.list',
    },
    {
        component: ShowUpcomingDeliveries,
        meta: {
            middleware: [auth],
            permissions: ['READ_SUBSCRIPTION'],
            title: 'Livraisons à venir ',
        },
        path: '/subscription/upcoming-deliveries',
        name: 'subscriptions.upcoming.deliveries',
    },
    {
        component: ShowSkipDates,
        meta: {
            middleware: [auth],
            permissions: ['READ_SUBSCRIPTION'],
            title: 'Date sautées',
        },
        path: '/subscription/skipped-dates',
        name: 'subscriptions.skipped-dates',
    },
]