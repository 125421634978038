import apiService from './Base/BaseRepository';

const url = '/subscriptions';

export default {
    async getPaginatedUpcomingDeliveries(
        perPage = 20,
        page = 1,
        sorts = [],
        filters = [],
        withTotals = 0
    ) {
        return await apiService
            .setPagination(perPage, page, withTotals)
            .setSorts(sorts)
            .setFilters(filters)
            .get(`${url}/upcoming-deliveries`);
    },

    async getAllPaginated(
        perPage = 20,
        page = 1,
        sorts = [],
        filters = [],
        withTotals = 0
    ) {
        return await apiService
            .setPagination(perPage, page, withTotals)
            .setSorts(sorts)
            .setFilters(filters)
            .get(`${url}/`);
    },
    async getSkippedDatesPaginated(
        perPage = 20,
        page = 1,
        sorts = [],
        filters = [],
        withTotals = 0
    ) {
        return await apiService
            .setPagination(perPage, page, withTotals)
            .setSorts(sorts)
            .setFilters(filters)
            .get(`${url}/skip-dates`);
    },
}