<template>
  <div>
    <list-table
      :batch-actions="internalOnly ? [] : InvoiceBatchActions.batchActions"
      :columns="getColumns"
      :create-route="getCreateRouteObject"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :select-values="InvoiceBatchActions.selectValues"
      :title="title"
      enable-totals
      identifier="invoicesTable"
      selectable
      show-filters-immediately
    >

      <template v-slot:payment-status-template="{ row }">
        <payment-invoice-status
          :status="row.paymentStatus"
          small
        />
      </template>

      <template v-slot:invoice-status="{ row }">
          <invoice-status :status="row.status" small></invoice-status>
      </template>

      <template v-slot:external-internal-template="{ row }">
        <span class="text-xl text-gray-700">
          <fa-icon
            v-if="row.isExternal"
            icon="sign-out-alt"
            title="Externe"
          />
          <fa-icon
            v-else
            icon="sign-in-alt"
            title="Interne"
          />
        </span>
      </template>
      <template v-slot:member-quick-action-template="{ row }">
          <member-quick-action-modal :id="row.memberId"/>
      </template>

      <template v-slot:order-id-template="{ row }">
          <text-link
            v-if="row.orderId"
            :to="{name: 'orders.edit', params: {order: row.orderId}}"
          >
            #{{ row.orderId }}
          </text-link>
          <div v-else>
            /
          </div>
      </template>
      <template v-slot:type-template="{ row }">
        <span class="text-xl text-gray-700">
          <fa-icon
            v-if="row.type === 'customer'"
            icon="user"
            title="Client"
          />
          <fa-icon
            v-else
            icon="truck"
            title="Fournisseur"
          />
        </span>
      </template>
    </list-table>
      <div id="report" class="mb-4; mt-5">
        <booking-account-report-table
          :data-set="getBatchResult"
          class="mb-4; mt-5"
          v-if="getBatchResult != null"
        />
      </div>

  </div>


</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import PaymentInvoiceStatus from "@/components/pages/Invoices/Shared/Misc/PaymentInvoiceStatus";
import InvoiceRepository from "@/repositories/InvoiceRepository";
import {
  FILTER_BOOKING_ACCOUNT,
  FILTER_CURRENCY,
  FILTER_DATETIME_RANGE,
  FILTER_SELECT_MULTI,
  FILTER_SELECT_SINGLE,
  FILTER_SINGLE_BOOLEAN,
} from "@/components/listTable/includes/controls/filters/availableFilters";
import {centsToEuroString, centsToMoneyString, convertAmountToEurCents} from "@/helpers/mathHelper";
import TextLink from "../../../../global/TextLink";
import MemberQuickActionModal from "../../../Members/Subcomponents/MemberQuickActionModal";
import {FILTER_BOOLEAN, FILTER_NUMERIC} from "../../../../listTable/includes/controls/filters/availableFilters";
import InvoiceStatus from "../Misc/InvoiceStatus";
import InvoiceBatchActions from "@/batchAction/invoiceBatchActions";
import BookingAccountReportTable from "@/components/pages/Accounting/VatReport/Table/BookingAccountReportTable.vue";
import {mapGetters} from "vuex";

export default {
  name: "SharedInvoiceListTable",
  components: {
      BookingAccountReportTable,
      InvoiceStatus, ListTable, PaymentInvoiceStatus, TextLink, MemberQuickActionModal},
  props: {
    internalOnly: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ...mapGetters('listTable', ['getBatchResult']),
    InvoiceBatchActions() {
      return InvoiceBatchActions
    },
    getCreateRouteObject() {
      return this.internalOnly
        ? {name: 'invoices.create'}
        : {name: 'accounting.invoices.create'};
    },
    getColumns() {


      const editRoute = this.internalOnly
        ? 'invoices.edit'
        : 'accounting.invoices.edit';


      let columns = [{
        caption: "Actions",
        buttons: [
          {
            type: 'show',
            hint: 'Voir facture',
            route: (row) => ({
              name: editRoute,
              params: {id: row.id},
              query: {readonly: true}
            }),
          },
          {
            type: 'edit',
            hint: 'Modifier facture',
            route: (row) => ({
              name: editRoute,
              params: {
                id: row.id
              }
            }),
          },
          {
            type: 'delete',
            hint: 'Supprimer facture',
            confirmation: {
              title: 'Supprimer la facture?',
              text: 'Étes vous sure que vous voulez supprimer cette facture?'
            },
            // Dont allow deleting finalized internal invoices
            disabledIf: (invoice) => !invoice.isExternal && !invoice.isDraft,
            successText: 'La facture a été supprimée.',
            deleteAction: (invoice) => !invoice.isExternal
              ? InvoiceRepository.internal.deleteSingle(invoice.id)
              : InvoiceRepository.external.deleteSingle(invoice.id)
          }
        ]
      }];

      if (!this.internalOnly) {
        columns.push({
            caption: 'Valide',
            calculateCellValue: (r) => {
              return !r.isError;
            },
            dataType: 'boolean',
            width: 0,
          },
          {
            caption: 'No.',
            dataField: 'invoiceNumber',
            width: 0,
          }
        );
      }

      columns = columns.concat([
        {
          caption: 'Id',
          dataField: 'id',
          width: 80,
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Mem.',
          cellTemplate: 'member-quick-action-template'
        },
        {
          caption: 'Com.',
          cellTemplate: 'order-id-template'
        },
        {
            caption: 'Abonn.',
            dataField: 'subscriptionId',
            width: 80,
            sort: {
                dataField: 'id',
            },
            visible: false
        },
        {
          caption: 'Status',
          dataField: 'status',
          cellTemplate: "invoice-status"
        },
      ]);


      if (!this.internalOnly) {
        columns.push({
          caption: 'Type',
          cellTemplate: "type-template",
          width: 0,
        });
        columns.push({
          caption: 'In/Ex',
          cellTemplate: "external-internal-template",
          width: 0,
        });
      }

      columns = columns.concat([
        {
          caption: "Paiement",
          cellTemplate: "payment-status-template"
        },
        {
          caption: 'Nom',
          dataField: 'fullName',
        },
        {
          caption: 'Numero TVA',
          dataField: 'vatNumber',
          visible: false,
        },
        {
          caption: 'Crée',
          calculateCellValue: (r) => {
            return this.$date(r.createdAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {
            dataField: 'createdAt'
          }
        },
        {
          caption: 'Date',
          dataField: 'date',
          calculateCellValue: (r) => {
            return this.$date(r.date).format('DD.MM.YYYY');
          },
          sort: {
            dataField: 'date',
          }
        },
        {
          caption: 'Date d\'écheance',
          dataField: 'dueDate',
          calculateCellValue: (r) => {
            if (r.dueDate === null) {
              return '/';
            }
            return this.$date(r.dueDate).format('DD.MM.YYYY');
          },
          visible: false,
          sort: {
            dataField: 'dueDate',
          }
        },
        {
          caption: 'Total TTC',
          dataType: 'euro',
          calculateCellValue: r => {
            let centsInMoney = centsToMoneyString(r.total.amount, r.currency.code);

            return r.total.amount < 0
              ? `<span class="text-red-500">${centsInMoney}</span>`
              : centsInMoney;
          },
          totals: {
            // Convert USD To Eur & Show Total Sum in EUR
            custom: (allRows) => {
              return allRows.reduce(function (sum, current) {
                return sum + parseInt(convertAmountToEurCents(current.total.amount, current.currency.code, current.currencyToEuroConversionRate));
              }, 0);
            },
            // Format Cents in EUR & suffix € symbol
            formatValue: (val) => centsToEuroString(val),
          },
          sort: {
            dataField: 'totalAmount',
          }
        },
        {
          caption: 'Total HTVA',
          dataType: 'euro',
          calculateCellValue: r => {
            let centsInMoney = centsToMoneyString(r.total.amountNoVat, r.currency.code);

            return r.total.amountNoVat < 0
              ? `<span class="text-red-500">${centsInMoney}</span>`
              : centsInMoney;
          },
          totals: {
            // Convert USD To Eur & Show Total Sum in EUR
            custom: (allRows) => {
              return allRows.reduce(function (sum, current) {
                return sum + parseInt(convertAmountToEurCents(current.total.amountNoVat, current.currency.code, current.currencyToEuroConversionRate));
              }, 0);
            },
            // Format Cents in EUR & suffix € symbol
            formatValue: (val) => centsToEuroString(val)
          },
        },
        {
          caption: 'Total VAT',
          calculateCellValue: r => {
            let centsInMoney = centsToMoneyString(r.total.amount - r.total.amountNoVat, r.currency.code);

            return (r.total.amount - r.total.amountNoVat) < 0
              ? `<span class="text-red-500">${centsInMoney}</span>`
              : centsInMoney;
          },
          dataType: 'euro',
          totals: {
            // Convert USD To Eur, Calc Amount with VAT minus Amount without VAT & Show Total Sum in EUR
            custom: (allRows) => {
              const amountWithVat = allRows.reduce(function (sum, current) {
                return sum + parseInt(convertAmountToEurCents(current.total.amount, current.currency.code, current.currencyToEuroConversionRate));
              }, 0);
              const amountWithoutVat = allRows.reduce(function (sum, current) {
                return sum + parseInt(convertAmountToEurCents(current.total.amountNoVat, current.currency.code, current.currencyToEuroConversionRate));
              }, 0);

              return amountWithVat - amountWithoutVat;
            },
            // Format Cents in EUR & suffix € symbol
            formatValue: (val) => centsToEuroString(val)
          },
        },
        {
          caption: 'Montant non payé',
          dataType: 'euro',
          visible: false,
          calculateCellValue: r => {
            let centsInMoney = centsToMoneyString(r.unpaidAmount, r.currency.code);

            return r.unpaidAmount < 0
              ? `<span class="text-red-500">${centsInMoney}</span>`
              : centsInMoney;
          },
          sort: {
            dataField: 'unpaidAmount',
          },
          totals: {
            // Convert USD To Eur & Show Total Sum in EUR
            custom: (allRows) => {
              return allRows.reduce(function (sum, current) {
                return sum + parseInt(convertAmountToEurCents(current.unpaidAmount, current.currency.code, current.currencyToEuroConversionRate));
              }, 0);
            },
            // Format Cents in EUR & suffix € symbol
            formatValue: (val) => centsToEuroString(val)
          },
        },
      ]);

      if (!this.internalOnly) {
        columns.push({
          caption: "Exp. Multiline",
          dataField: 'multilineExportCount',
          visible: false
        });
      }


      return columns;
    }
  },
  data() {
    const repoFunc = this.internalOnly
      ? InvoiceRepository.internal.getPaginated
      : InvoiceRepository.getPaginated;


    let filters = [
      {
        caption: "ID(s)",
        filterKey: "invoiceIds",
        small: true,
        forceIsVisible: true,
      },
      {
        caption: "Nombre",
        filterKey: "number",
        small: true,
        forceIsVisible: true,

      },
      {
        caption: "ID(s) ou Nom Membre",
        filterKey: "memberIdsOrName",
        forceIsVisible: true,
        small: true,
      },
      {
        caption: "Commande",
        filterKey: "orderId",
        small: true,
        forceIsVisible: true,
      },
      {
        caption: "Abbon.",
        filterKey: "subscriptionId",
        small: true,
        forceIsVisible: false,
      },
      {
        caption: "Total TTC",
        filterKey: "totalTTC",
        type: FILTER_CURRENCY,
        small: true,
      },
      {
        filterKey: 'date',
        caption: "Date entre",
        type: FILTER_DATETIME_RANGE,
        forceIsVisible: true,
      },
      {
        filterKey: 'createdAt',
        caption: "Crée entre",
        type: FILTER_DATETIME_RANGE
      },
      {
        filterKey: 'dueDate',
        caption: "Date d'echéance entre",
        type: FILTER_DATETIME_RANGE,
        forceIsVisible: !this.internalOnly,
      },
      {
        filterKey: 'totalAmount',
        inputs: [
          {
            caption: 'Montant TTC de',
            inputKey: 'totalAmount_from',
            type: FILTER_CURRENCY
          },
          {
            caption: 'à',
            inputKey: 'totalAmount_to',
            type: FILTER_CURRENCY
          }
        ]
      },
      {
        caption: "Status",
        filterKey: "status",
        filterOptions: this.$luxcaddyConfig('invoice.availableStatuses'),
        type: FILTER_SELECT_MULTI,
        small: true
      },
      {
        caption: 'Status Payé',
        filterKey: 'paymentStatus',
        filterOptions: this.$luxcaddyConfig('invoice.availablePaymentStatuses'),
        type: FILTER_SELECT_MULTI,
        small: true,
        forceIsVisible: true,
      },
      {
        caption: "Prestataire",
        filterKey: "paymentProvider",
        type: FILTER_SELECT_SINGLE,
        small: true,
        filterOptions: this.$luxcaddyConfig('invoice.paymentTransactions.paymentProviders')
      },
      {
          caption: 'Avec abbon.',
          filterKey: 'isFromSubscription',
          type: FILTER_BOOLEAN,
          small: true
      },
    ];

    if (!this.internalOnly) {
      filters = filters.concat([
        {
          caption: 'Type',
          filterKey: 'type',
          type: FILTER_SELECT_SINGLE,
          forceIsVisible: true,
          small: true,
          filterOptions: [
            {value: null, label: 'Tous'},
            {value: 'customer', label: 'Client'},
            {value: 'supplier', label: 'Fournisseur'},
          ]
        },
        {
          caption: 'In/Ex',
          filterKey: 'isExternal',
          type: FILTER_SELECT_SINGLE,
          small: true,
          filterOptions: [
            {value: null, label: 'Tous'},
            {value: 1, label: 'Externe'},
            {value: 0, label: 'Interne'},
          ]
        },
        {
          caption: 'Seulement erronés',
          filterKey: 'error',
          type: FILTER_SINGLE_BOOLEAN,
          small: true
        },
        {
          caption: 'Export Multilines X fois',
          filterKey: 'multilineExportCount',
          type: FILTER_NUMERIC,
          forceIsVisible: true,
          small: true
        },
        {
          caption: 'Compte Booking',
          filterKey: 'bookingAccountId',
          type: FILTER_BOOKING_ACCOUNT,
        },
        {
          caption: 'Fournisseur de marchandise?',
          filterKey: 'isMemberProductSupplier',
          type: FILTER_BOOLEAN,
          small: true
        },
      ]);
    }

    return {
      selectValues: invoice => {
        return {
          id: invoice.id
        };
      },
      title: 'Factures',
      repositoryFunc: repoFunc,
      filters: filters
    }
  },
  watch: {
    getBatchResult(newResult) {
      if (newResult !== null) {
          document.getElementById(`report`).scrollIntoView({behavior: 'smooth'});
      }
    }
  }

}
</script>

<style scoped>

</style>