<template>
    <div>
        <tooltip text="Cliquez pour ouvrir le menu d'action rapide">
            <text-link
                    @click="openModal"
            >
                #{{ subscriptionId }}
            </text-link>
        </tooltip>


        <quick-action-modal-base
                ref="subscriptionModal"
                :links="getQuickActionLinks"
                :title="getTitle"
                limit-link-width
        >
        </quick-action-modal-base>
    </div>
</template>

<script>
import Tooltip from "@/components/elements/tooltips/Tooltip";
import TextLink from "@/components/global/TextLink";
import newTabMixin from "@/mixins/newTabMixin";
import QuickActionModalBase from "@/components/global/QuickActionModal/QuickActionModalBase.vue";

export default {
    name: "SubscriptionQuickActionModal",
    components: {QuickActionModalBase, TextLink, Tooltip},
    data: () => ({
        member: null,
    }),
    mixins: [newTabMixin],
    props: {
        subscriptionId: {
            type: Number,
            required: true
        }
    },
    computed: {
        getTitle() {
            return 'Abonnament #' + this.subscriptionId
        },
        getQuickActionLinks() {
            return [
                {

                    label: 'Livraison a venir',
                    route: {
                        name: 'subscriptions.upcoming.deliveries',
                        query: {
                            filter_timeslot: this.$date().startOf('day').format('DD.MM.YYYY HH:mm:ss') + ',' + this.$date().endOf('day').add(1, 'month').format('DD.MM.YYYY HH:mm:ss'),
                            filter_subscriptionIds: this.subscriptionId
                        }
                    },
                    icon: 'truck'
                },
                {
                    label: 'Livraisons passées',
                    route: {
                        name: 'deliveries.list',
                        query: {
                            filter_subscriptionId: this.subscriptionId
                        }
                    },
                    icon: 'truck'
                },
                {
                    label: 'Voir commandes',
                    route: {
                        name: 'orders.list',
                        params: {subscriptionId: this.subscriptionId},
                        query: {
                            filter_subscriptionId: this.subscriptionId
                        }
                    },
                    icon: 'boxes'
                },
                {
                    label: 'Voir Factures',
                    route: {
                        name: 'invoices.list',
                        query: {
                            filter_subscriptionId: parseInt(this.subscriptionId)
                        }
                    },
                    icon: 'file-alt'
                } ,
                {
                    label: 'Date sautées',
                    route: {
                        name: 'subscriptions.skipped-dates',
                        query: {
                            filter_subscriptionIds: parseInt(this.subscriptionId)
                        }
                    },
                    icon: 'file-alt'
                }
            ];
        }
    },
    methods: {
        openModal() {
            return this.$refs.subscriptionModal.openModal();
        },
    }
}
</script>

<style scoped>

</style>