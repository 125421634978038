<template>
  <div>
    <list-table
      :columns="columns"
      :create-route="{
                name: 'blog.posts.create'
            }"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      identifier="blogPostsTable"
      title="Blog & Recettes"
    >
      <template v-slot:type-template="{row}">
        <blog-post-type-label
          :type="row.type"
        />
      </template>

      <template v-slot:publish-status-template="{row}">
        <blog-post-publish-status
          :published-at="row.publishedAt"
        />
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import BlogRepository from "@/repositories/BlogRepository";
import BlogPostPublishStatus from "@/components/pages/Blog/Posts/Table/Components/BlogPostPublishStatus";
import {FILTER_BOOLEAN, FILTER_SELECT_SINGLE} from "@/components/listTable/includes/controls/filters/availableFilters";
import BlogPostTypeLabel from "@/components/pages/Blog/Posts/Table/Components/BlogPostTypeLabel";

export default {
  name: "ShowBlogPostTable",
  components: {BlogPostTypeLabel, BlogPostPublishStatus, ListTable},
  data() {
    return {
      title: "Blog",
      repositoryFunc: BlogRepository.posts.getPaginated,
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'edit',
              hint: 'Modifier article',
              route: (row) => ({
                name: 'blog.posts.update',
                params: {
                  blogPostId: row.id,
                }
              }),
            },
            {
              type: 'delete',
              hint: 'Supprimer article',
              confirmation: {
                title: 'Supprimer article?',
                text: 'Étes vous sure que vous voulez supprimer cette article?'
              },
              successText: 'Le article a été supprimé',
              deleteAction: (row) => BlogRepository.posts.deletePost(row.id),
              deletePermissions: ['BLOG_WRITE']
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Type',
          cellTemplate: 'type-template'
        },
        {
          caption: 'Titre',
          dataField: 'title',
          sort: {
            dataField: 'title'
          },
        },
        {
          caption: 'Publiée',
          cellTemplate: 'publish-status-template',
          sort: {
            dataField: 'publishedAt'
          },
        },
        {
          caption: 'Listé Luxcaddy',
          dataType: 'boolean',
          calculateCellValue: (row) => row.isListed,
        },
        {
          caption: 'Listé Freshly',
          dataType: 'boolean',
          calculateCellValue: (row) => row.isListedFreshly,
        },
      ],
      filters: [
        {
          caption: "Titre",
          filterKey: "title",
          forceIsVisible: true,
        },
        {
          caption: "Type",
          filterKey: "type",
          type: FILTER_SELECT_SINGLE,
          filterOptions: [
            {label: 'Blog', value: 'BlogPost'},
            {label: 'Recettes', value: 'Recipe'},
            {label: 'Faq', value: 'Faq'}
          ],
          forceIsVisible: true,
        },
        {
          caption: "Publiée",
          filterKey: "published",
          type: FILTER_BOOLEAN,
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Listé - Luxcaddy",
          filterKey: "listed",
          type: FILTER_BOOLEAN,
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Listé - Freshly",
          filterKey: "listedFreshly",
          type: FILTER_BOOLEAN,
          forceIsVisible: true,
          small: true,
        },
      ],
    }
  },
}
</script>